<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <dashboard ref="dashboard" @search="handleGetResumoTerminal" />
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <resumo-terminal-detalhado ref="detalhado"></resumo-terminal-detalhado>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <ultimas-chamadas ref="chamadas"></ultimas-chamadas>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ResumoTerminalDetalhado from '@/components/patio/resumoTerminal/ResumoTerminalDetalhado.vue'
import Dashboard from '@/components/patio/resumoTerminal/Dashboard.vue'
import UltimasChamadas from '@/components/patio/resumoTerminal/UltimasChamadas.vue'

import { toReal, parseDatetime, date2BRstring } from '@/mixins/convertion.js'
export default {
  name: 'Servico',

  components: {
    ResumoTerminalDetalhado,
    Dashboard,
    UltimasChamadas,
  },
  mixins: [toReal, parseDatetime, date2BRstring],
  data: function () {
    return {
      tab: 'listagem',
      page: {
        title: 'Relatório de resumo por terminal',
      },
      breadcrumbs: [
        {
          text: 'Patio',
          disabled: true,
        },
        {
          text: 'Terminal',
          disabled: true,
        },
        {
          text: 'Relatório',
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
  },
  methods: {
    ...mapMutations('resumoTerminal', ['UPDATE_PARAMS']),
    async handleGetResumoTerminal() {
      await this.$refs['detalhado'].getServico()
      await this.$refs['chamadas'].getChamadas()
    },
  },
  created() {
    if (this.empresaAtual.gate) {
      // let data_inicio = new Date()
      // data_inicio.setDate(data_inicio.getDate() - 1)
      this.UPDATE_PARAMS({
        owner: this.empresaAtual.public_id,
        gate: this.empresaAtual.public_id,
        proprietario: this.empresaAtual.public_id,
        // data_inicio: this.date2BRstring(data_inicio),
      })
    }
  },
}
</script>
