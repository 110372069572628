<template>
  <div class="mt-4 mb-3">
    <v-overlay v-model="carregandoDados">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card
      v-for="item in this.tefSubProduto"
      :key="item.terminal"
      class="mb-7"
    >
      <v-card-title class="pa-5 border-bottom">
        <h3
          class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
        >
          {{ item.terminal }}
        </h3>
      </v-card-title>
      <v-card-text class="pa-5 border-bottom">
        <v-data-table
          :headers="headersResumoSubProduto"
          :items="item.subProdutos"
          :hide-default-footer="true"
          :item-class="itemRowBackground"
        >
          <template v-slot:[`item.EM_PATIO.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.EM_PATIO[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.EM_PATIO[0].peso).toFixed(2) }} <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.AGENDADOS.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.AGENDADOS[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.AGENDADOS[0].peso).toFixed(2) }} <b>t</b>
                </td>
              </tr>
              <!-- <tr>
                <td><v-img class="float-left mr-2 ml-2 img-button-modal-secundary" max-height="24" max-width="24" src="@/assets/images/icon/relogio.png"/></td>
                <td><b>TEF:</b> {{ getTempoEmPatioClassificacao(item) }}</td>
              </tr> -->
            </table>
          </template>

          <template v-slot:[`item.tef.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/relogio.png"
                  />
                </td>
                <td><b>TEF:</b> {{ getTempoEmPatio(item) }}</td>
              </tr>
            </table>
          </template>

          <template
            v-slot:[`item.LIBERADO_PARA_CARGA_DESCARGA.value`]="{ item }"
          >
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>
                  {{ item.LIBERADO_PARA_CARGA_DESCARGA[0].value }} <b>Cms</b>
                </td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{
                    parseFloat(
                      item.LIBERADO_PARA_CARGA_DESCARGA[0].peso
                    ).toFixed(2)
                  }}
                  <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template
            v-slot:[`item.CHAMADO_PARA_CARGA_DESCARGA.value`]="{ item }"
          >
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>
                  {{ item.CHAMADO_PARA_CARGA_DESCARGA[0].value }} <b>Cms</b>
                </td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{
                    parseFloat(
                      item.CHAMADO_PARA_CARGA_DESCARGA[0].peso
                    ).toFixed(2)
                  }}
                  <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.AGUARDANDO_CHAMADA.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.AGUARDANDO_CHAMADA[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.AGUARDANDO_CHAMADA[0].peso).toFixed(2) }}
                  <b>Toneladas</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.AGUARDANDO_ANÁLISE.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.AGUARDANDO_ANÁLISE[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.AGUARDANDO_ANÁLISE[0].peso).toFixed(2) }}
                  <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.EM_TRANSITO.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.EM_TRANSITO[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.EM_TRANSITO[0].peso).toFixed(2) }} <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.AGUARDANDO_CLASSIFICAÇÃO.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.AGUARDANDO_CLASSIFICAÇÃO[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{
                    parseFloat(item.AGUARDANDO_CLASSIFICAÇÃO[0].peso).toFixed(2)
                  }}
                  <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.TRANSITO_PARA_FABRICA.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.TRANSITO_PARA_FABRICA[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{
                    parseFloat(item.TRANSITO_PARA_FABRICA[0].peso).toFixed(2)
                  }}
                  <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.EM_PROCESSO.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.EM_PROCESSO[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.EM_PROCESSO[0].peso).toFixed(2) }} <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.AGUARDANDO_ORDEM.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.AGUARDANDO_ORDEM[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.AGUARDANDO_ORDEM[0].peso).toFixed(2) }}
                  <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.PATIO_BUNGE.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>{{ item.PATIO_BUNGE[0].value }} <b>Cms</b></td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/tonelada.png"
                  />
                </td>
                <td>
                  {{ parseFloat(item.PATIO_BUNGE[0].peso).toFixed(2) }} <b>t</b>
                </td>
              </tr>
            </table>
          </template>

          <template v-slot:[`item.ultima_chamada.value`]="{ item }">
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>
                  <b>{{ item.ultima_chamada.quantidade }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/relogio.png"
                  />
                </td>
                <td>{{ formatDate(item.ultima_chamada.value) }}</td>
              </tr>
            </table>
          </template>

          <template
            v-slot:[`item.quantidade_descarregados_hoje.value`]="{ item }"
          >
            <table style="padding: 15px">
              <tr>
                <td>
                  <v-img
                    class="float-left mr-2 ml-2 img-button-modal-secundary"
                    max-height="24"
                    max-width="24"
                    src="@/assets/images/icon/caminhao-de-entrega.png"
                  />
                </td>
                <td>
                  {{ item.quantidade_descarregados_hoje.value }} <b>Cms</b>
                </td>
              </tr>
            </table>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'RelatorioDetalhado',

  mixins: [toReal],

  data() {
    return {
      interval: null,
      intervaloEmSegundos: 300,
      carregandoDados: false,
      expanded: [],
      singleExpand: false,
      faturamento: [],
      tefSubProduto: [],
      relatorioResumoTerminal: [],
      relatorioResumoPatio: [],
      search: '',
      dialog: null,
      headers: [
        {
          text: 'terminal',
          align: 'start',
          value: 'terminal',
        },
      ],
      headersResumoSubProduto: [
        { text: 'Fila', value: 'descricao' },
        { text: 'Aguardando Liberação', value: 'quantidade_em_patio.value' },

        { text: 'A Classificar', value: 'quantidade_em_classificacao.value' },
        { text: 'Última Chamada', value: 'ultima_chamada.value' },
        {
          text: 'Descarregado desde as 07:00',
          value: 'quantidade_descarregados_hoje.value',
        },
      ],
      headersDetalhamento: [
        { text: 'Produto', value: 'produto' },
        { text: 'Agendados (QTD)', value: 'agendados.quantidade_caminhoes' },
        {
          text: 'A Classificar (QTD)',
          value: 'a_classificar.quantidade_caminhoes',
        },
        {
          text: 'Aguardando Liberação (QTD)',
          value: 'aguardando_descarga.quantidade_caminhoes',
        },

        {
          text: 'Descarregados hoje (QTD)',
          value: 'descarregados_hoje.quantidade_caminhoes',
        },

        {
          text: 'Descarregados ontem (QTD)',
          value: 'descarregados_ontem.quantidade_caminhoes',
        },

        { text: 'TEF', value: 'tef.value' },
        { text: 'Última chamada', value: 'ultima_chamada.value' },
      ],
      terminais: [
        {
          terminal: 'SCPAR PORTO DE SAO FRANCISCO DO SUL S.A.',
          produtos: [
            {
              produto: 'OLEO BUNGE',
              agendados: {
                quantidade_caminhoes: 2,
                peso_total: {
                  qt_peso: 74.0,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'FARELO BUNGE',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'MILHO',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'SOJA',
              agendados: {
                quantidade_caminhoes: 283,
                peso_total: {
                  qt_peso: 10373.74899999999,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 21,
                peso_total: {
                  qt_peso: 760.35,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 14,
                peso_total: {
                  qt_peso: 581.1,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
          ],
        },
        {
          terminal: 'BUNGE ALIMENTOS S/A * SÃO FCO DO SUL/SC',
          produtos: [
            {
              produto: 'OLEO BUNGE',
              agendados: {
                quantidade_caminhoes: 7,
                peso_total: {
                  qt_peso: 184.0,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'FARELO BUNGE',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'MILHO',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'SOJA',
              agendados: {
                quantidade_caminhoes: 324,
                peso_total: {
                  qt_peso: 11936.147999999994,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 12,
                peso_total: {
                  qt_peso: 425.16,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 26,
                peso_total: {
                  qt_peso: 1026.03,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
          ],
        },
        {
          terminal: 'AMAGGI EXP. E IMP. LTDA - SAO FCO DO SUL-SC',
          produtos: [
            {
              produto: 'OLEO BUNGE',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'FARELO BUNGE',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'MILHO',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
            {
              produto: 'SOJA',
              agendados: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              a_classificar: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              aguardando_descarga: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_hoje: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              descarregados_ontem: {
                quantidade_caminhoes: 0,
                peso_total: {
                  qt_peso: null,
                },
              },
              tef: {
                value: '10:00',
              },
              ultima_chamada: {
                value: '20/05/2022 00:20',
              },
            },
          ],
        },
      ],
      headersResumoPatio: [
        {
          text: 'Agendados (QTD)',
          value: 'produtos.resumo_patio.agendados.quantidade_caminhoes',
        },
        {
          text: 'Agendados (t)',
          value: 'produtos.resumo_patio.agendados.peso_total',
        },
        {
          text: 'A Classificar (QTD)',
          value: 'produtos.resumo_patio.a_classificar.quantidade_caminhoes',
        },
        {
          text: 'A Classificar (t)',
          value: 'produtos.resumo_patio.a_classificar.peso_total',
        },
        {
          text: 'Aguardando Liberação (QTD)',
          value:
            'produtos.resumo_patio.aguardando_descarga.quantidade_caminhoes',
        },
        {
          text: 'Aguardando Liberação (t)',
          value: 'produtos.resumo_patio.aguardando_descarga.peso_total',
        },
        {
          text: 'Descarregados hoje (QTD)',
          value:
            'produtos.resumo_patio.descarregados_hoje.quantidade_caminhoes',
        },
        {
          text: 'Descarregados hoje (t)',
          value: 'produtos.resumo_patio.descarregados_hoje.peso_total',
        },
        {
          text: 'Descarregados ontem (QTD)',
          value:
            'produtos.resumo_patio.descarregados_ontem.quantidade_caminhoes',
        },
        {
          text: 'Descarregados ontem (t)',
          value: 'produtos.resumo_patio.descarregados_ontem.peso_total',
        },
      ],
      resumo_patio: {
        agendados: {
          quantidade_caminhoes: 645,
          peso_total: {
            qt_peso: 23449.347000000034,
          },
        },
        a_classificar: {
          quantidade_caminhoes: 33,
          peso_total: {
            qt_peso: 1185.51,
          },
        },
        aguardando_descarga: {
          quantidade_caminhoes: 40,
          peso_total: {
            qt_peso: 1607.1300000000003,
          },
        },
        descarregados_hoje: {
          quantidade_caminhoes: 0,
          peso_total: {
            qt_peso: null,
          },
        },
        descarregados_ontem: {
          quantidade_caminhoes: 0,
          peso_total: {
            qt_peso: null,
          },
        },
      },
      fields_excel: {
        Produto: 'produto',
        'Agendados (QTD)': 'agendados.quantidade_caminhoes',
        'Agendados (t)': 'agendados.peso_total',
        'A Classificar (QTD)': 'a_classificar.quantidade_caminhoes',
        'A Classificar (t)': 'a_classificar.peso_total',
        'Aguardando Liberação (QTD)':
          'aguardando_descarga.quantidade_caminhoes',
        'Aguardando Liberação (t)': 'aguardando_descarga.peso_total',
        'Descarregados hoje (QTD)': 'descarregados_hoje.quantidade_caminhoes',
        'Descarregados hoje (t)': 'descarregados_hoje.peso_total',
        'Descarregados ontem (QTD)': 'descarregados_ontem.quantidade_caminhoes',
        'Descarregados ontem (t)': 'descarregados_ontem.peso_total',
        TEF: 'tef.value',
        'Última chamada': 'ultima_chamada.value',
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('resumoTerminal', ['params']),
  },
  methods: {
    ...mapActions('patio', [
      'getResumoTerminal',
      'getResumoPatio',
      'getResumoSubProduto',
    ]),
    ...mapActions('template', ['errorMessage']),
    itemRowBackground: function (item) {
      const diferencaEmMs = new Date() - Date.parse(item.tef.value)
      const segundos = diferencaEmMs / 1000
      const horas = segundos / 3600
      let color1 = horas > 8 ? 'style-2' : 'style-1'
      console.log(color1)
      let color = 'style-1'
      return color
    },
    carregarDados() {
      this.carregandoDados = true
    },
    colorClass(item) {
      if (item.tef.value !== null) {
        const diferencaEmMs = new Date() - Date.parse(item.tef.value)
        const segundos = diferencaEmMs / 1000
        const horas = segundos / 3600
        let color = horas > 8 ? 'red' : 'green'
        return 'color: ' + color
      } else {
        return 'color: red'
      }
    },
    getTempoEmPatio(item) {
      if (item.tef.value !== null && item.tef.value !== '') {
        const diferencaEmMs = new Date() - Date.parse(item.tef.value)
        const tempo = diferencaEmMs / 1000
        const horas = Math.floor(tempo / (60 * 60))
        const minutos = Math.floor((tempo % (60 * 60)) / 60)
        return `${horas} H e ${minutos} m`
      } else {
        return '0 H e 0 m'
      }
    },
    getTempoEmPatioClassificacao(item) {
      if (
        item.tef_classificacao.value !== null &&
        item.tef_classificacao.value !== ''
      ) {
        const diferencaEmMs =
          new Date() - Date.parse(item.tef_classificacao.value)
        const tempo = diferencaEmMs / 1000
        const horas = Math.floor(tempo / (60 * 60))
        const minutos = Math.floor((tempo % (60 * 60)) / 60)
        return `${horas}:${minutos}`
      } else {
        return '00:00:00'
      }
    },
    finalizarCarregamentoDados() {
      this.carregandoDados = false
    },
    formataPeso(item) {
      if (item == null) {
        item = 0
      }
      return parseFloat(item).toFixed(0)
    },
    formatDate(date) {
      if (!date) return null

      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    formatHora(value) {
      if (!value) return null

      return parseInt(value)
    },

    async getItems() {
      if (!Object.keys(this.params).length) return
      this.carregarDados()
      try {
        const tefSubProduto = await this.getResumoSubProduto({
          ...this.params,
        })
        this.tefSubProduto = tefSubProduto.terminais

        this.headersResumoSubProduto =
          JSON.parse(localStorage.getItem('headersResumoTerminal')) || []
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
        if (e.response) {
          this.errorMessage(e.response.data)
        }
      }
      this.finalizarCarregamentoDados()
    },

    async getServico() {
      await this.getItems()
      clearInterval(this.interval)
      this.interval = setInterval(
        this.getItems,
        this.intervaloEmSegundos * 1000
      )
    },
  },

  async created() {},
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>

<style>
.style-1 {
  background-color: white;
}
.style-2 {
  background-color: red;
}
</style>
<style scoped>
.v-data-table .v-data-table-header tr th {
  font-size: 35px !important;
}
.relatorio {
  background-color: rgb(204, 206, 205);
}
.div-qty-toneladas {
  height: 105px !important;
  padding-top: 15px !important;
}

.toneladas-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 28px !important;
  color: #00113a !important;
}

.toneladas-subtitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 17px !important;
  line-height: 110% !important;
  color: #00113a !important;
}

.toneladas-line {
  padding-left: 40px;
  margin-bottom: 30px;
}
</style>
