<template>
  <div>
    <v-card>
      <v-card-title>Filtros</v-card-title>
      <v-card-text>
        <v-treeview
          v-model="filterItems"
          selectable
          selection-type="independent"
          open-on-click
          transition
          dense
          item-text="descricao"
          item-key="id"
          item-children="etapas"
          return-object
          :items="filterOptions"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'DashboardFilter',

  data() {
    return {
      filterItems: [],
      statusItems: [
        {
          descricao: 'AGENDADOS',
          id: 'AGENDADO',
          etapas: []
        },
      ],
    }
  },

  computed: {
    ...mapState('patio', ['servicos', 'dashboardFilters', 'selectedFilters']),
    ...mapState('auth', ['empresaAtual']),

    filterOptions() {
      const options = this.servicos.map((servico) => {
        const etapas = servico.etapas.map((etapa) => ({
          descricao: etapa.nome,
          id: etapa.public_id,
          servico: {
            id: servico.id,
            descricao: servico.descricao,
          },
        }))

        return { ...servico, etapas }
      })
      return [...this.statusItems, ...options]
    },
  },

  methods: {
    ...mapMutations('patio', ['SET_SELECTED_FILTERS']),

    save() {
      this.$emit('save', this.filterItems)
      // this.SET_SELECTED_FILTERS(this.filterItems)
    },
  },

  async created() {
    Object.assign(this.filterItems, this.selectedFilters)
  },
}
</script>
