var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 mb-3"},[_c('v-overlay',{model:{value:(_vm.carregandoDados),callback:function ($$v) {_vm.carregandoDados=$$v},expression:"carregandoDados"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_vm._l((this.tefSubProduto),function(item){return _c('v-card',{key:item.terminal,staticClass:"mb-7"},[_c('v-card-title',{staticClass:"pa-5 border-bottom"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"},[_vm._v(" "+_vm._s(item.terminal)+" ")])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-data-table',{attrs:{"headers":_vm.headersResumoSubProduto,"items":item.subProdutos,"hide-default-footer":true,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"item.EM_PATIO.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.EM_PATIO[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.EM_PATIO[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.AGENDADOS.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.AGENDADOS[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.AGENDADOS[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.tef.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/relogio.png")}})],1),_c('td',[_c('b',[_vm._v("TEF:")]),_vm._v(" "+_vm._s(_vm.getTempoEmPatio(item)))])])])]}},{key:"item.LIBERADO_PARA_CARGA_DESCARGA.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(" "+_vm._s(item.LIBERADO_PARA_CARGA_DESCARGA[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat( item.LIBERADO_PARA_CARGA_DESCARGA[0].peso ).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.CHAMADO_PARA_CARGA_DESCARGA.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(" "+_vm._s(item.CHAMADO_PARA_CARGA_DESCARGA[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat( item.CHAMADO_PARA_CARGA_DESCARGA[0].peso ).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.AGUARDANDO_CHAMADA.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.AGUARDANDO_CHAMADA[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.AGUARDANDO_CHAMADA[0].peso).toFixed(2))+" "),_c('b',[_vm._v("Toneladas")])])])])]}},{key:"item.AGUARDANDO_ANÁLISE.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.AGUARDANDO_ANÁLISE[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.AGUARDANDO_ANÁLISE[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.EM_TRANSITO.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.EM_TRANSITO[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.EM_TRANSITO[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.AGUARDANDO_CLASSIFICAÇÃO.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.AGUARDANDO_CLASSIFICAÇÃO[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.AGUARDANDO_CLASSIFICAÇÃO[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.TRANSITO_PARA_FABRICA.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.TRANSITO_PARA_FABRICA[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.TRANSITO_PARA_FABRICA[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.EM_PROCESSO.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.EM_PROCESSO[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.EM_PROCESSO[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.AGUARDANDO_ORDEM.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.AGUARDANDO_ORDEM[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.AGUARDANDO_ORDEM[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.PATIO_BUNGE.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(_vm._s(item.PATIO_BUNGE[0].value)+" "),_c('b',[_vm._v("Cms")])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/tonelada.png")}})],1),_c('td',[_vm._v(" "+_vm._s(parseFloat(item.PATIO_BUNGE[0].peso).toFixed(2))+" "),_c('b',[_vm._v("t")])])])])]}},{key:"item.ultima_chamada.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_c('b',[_vm._v(_vm._s(item.ultima_chamada.quantidade))])])]),_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/relogio.png")}})],1),_c('td',[_vm._v(_vm._s(_vm.formatDate(item.ultima_chamada.value)))])])])]}},{key:"item.quantidade_descarregados_hoje.value",fn:function(ref){
var item = ref.item;
return [_c('table',{staticStyle:{"padding":"15px"}},[_c('tr',[_c('td',[_c('v-img',{staticClass:"float-left mr-2 ml-2 img-button-modal-secundary",attrs:{"max-height":"24","max-width":"24","src":require("@/assets/images/icon/caminhao-de-entrega.png")}})],1),_c('td',[_vm._v(" "+_vm._s(item.quantidade_descarregados_hoje.value)+" "),_c('b',[_vm._v("Cms")])])])])]}}],null,true)})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }