var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{staticClass:"my-8"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.canList)?_c('v-card',[_c('v-card-title',{staticClass:"pa-5 border-bottom"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"},[_vm._v(" Últimas chamadas ")])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-data-table',{attrs:{"headers":_vm.headersChamadas,"items":_vm.items,"options":_vm.options,"expanded":_vm.expanded,"item-key":"created_at","show-expand":"","server-items-length":this.dashboardAprovados.count || 10},on:{"update:options":[function($event){_vm.options=$event},_vm.getChamadas],"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.created_at))+" ")]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.finished_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"border rounded-0",attrs:{"headers":_vm.headersDetalhes,"items":item.chamadas,"disable-sort":true,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.tempoEmPatio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTempoEmPatio(item))+" ")]}},{key:"item.senhas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.senhas.length ? item.senhas[0] : '-')+" ")]}},{key:"item.triado_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.triado_em))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.created_at))+" ")]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.finished_at))+" ")]}},{key:"item.saida_do_patio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.saida_do_patio))+" ")]}},{key:"item.liberado_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.liberado_em))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.baixa_automatica",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"green--text"},[_vm._v(_vm._s(item.baixa_automatica))])]}}],null,true)})],1)]}}],null,false,3952001021)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }