<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-row class="my-8">
      <v-col cols="12" md="12">
        <v-card v-if="canList">
          <v-card-title class="pa-5 border-bottom">
            <h3
              class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
            >
              Últimas chamadas
            </h3>
          </v-card-title>
          <v-card-text class="pa-5 border-bottom">
            <v-data-table
              :headers="headersChamadas"
              :items="items"
              :options.sync="options"
              :expanded.sync="expanded"
              item-key="created_at"
              show-expand
              :server-items-length="this.dashboardAprovados.count || 10"
              @update:options="getChamadas"
            >
              <template #item.created_at="{ item }">
                {{ item.created_at | toLocaleDateTimeString }}
              </template>
              <template #item.finished_at="{ item }">
                {{ item.finished_at | toLocaleDateTimeString }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="ma-0 pa-0">
                  <v-data-table
                    :headers="headersDetalhes"
                    :items="item.chamadas"
                    class="border rounded-0"
                    :disable-sort="true"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:[`item.tempoEmPatio`]="{ item }">
                      {{ getTempoEmPatio(item) }}
                    </template>
                    <template v-slot:[`item.senhas`]="{ item }">
                      {{ item.senhas.length ? item.senhas[0] : '-' }}
                    </template>
                    <template #item.triado_em="{ item }">
                      {{ item.triado_em | toLocaleDateTimeString }}
                    </template>
                    <template #item.created_at="{ item }">
                      {{ item.created_at | toLocaleDateTimeString }}
                    </template>
                    <template #item.finished_at="{ item }">
                      {{ item.finished_at | toLocaleDateTimeString }}
                    </template>
                    <template #item.saida_do_patio="{ item }">
                      {{ item.saida_do_patio | toLocaleDateTimeString }}
                    </template>
                    <template #item.liberado_em="{ item }">
                      {{ item.liberado_em | toLocaleDateTimeString }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <span>{{ getStatus(item.status) }} </span>
                    </template>
                    <template v-slot:[`item.baixa_automatica`]="{ item }">
                      <v-icon  class="green--text" 
                        >{{ item.baixa_automatica }}</v-icon
                      >
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { toLocaleDateTimeString } from '@/mixins/convertion'
import { SolicitacaoBaixaStatus } from '@/utils/solicitacaoBaixa.js'
import prettyMilliseconds from 'pretty-ms'

export default {
  name: 'Dashboard',

  data() {
    return {
      headersChamadas: [
        { text: '', value: 'data-table-expand' },
        { text: 'Veiculos', value: 'chamadas.length' },
        { text: 'Solicitado', value: 'created_at' },
        { text: 'Solicitante', value: 'created_by.name' },
        { text: 'Terminal de origem', value: 'terminal_origem.contratado.business_name', },
        { text: 'Terminal de destino', value: 'terminal_destino.contratado.business_name', },
        { text: 'Fila', value: 'sub_produtos' },
        { text: 'Liberado em', value: 'finished_at' },
        { text: 'Liberado por', value: 'finished_by.name' },
      ],
      headersDetalhes: [
        {
          text: 'Senha',
          divider: true,
          value: 'senhas',
          class: 'primary white--text',
        },
        {
          text: 'Fornecedor',
          divider: true,
          value: 'fornecedor.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Placa Cavalo',
          divider: true,
          value: 'placa',
          class: 'primary white--text',
        },
        {
          text: 'Motorista',
          divider: true,
          value: 'motorista',
          class: 'primary white--text',
        },
        {
          text: 'Sub Produto',
          divider: true,
          value: 'sub_produtos',
          class: 'primary white--text',
        },
        {
          text: 'Embalagem',
          divider: true,
          value: 'embalagens',
          class: 'primary white--text',
        },
        {
          text: 'Terminal Destino',
          divider: true,
          value: 'terminal_destino.contratado.business_name',
          class: 'primary white--text',
        },
        {
          text: 'Triado Em:',
          divider: true,
          value: 'triado_em',
          class: 'primary white--text',
        },
        {
          text: 'Chamado por:',
          divider: true,
          value: 'created_by.name',
          class: 'primary white--text',
        },
        {
          text: 'Chamado Em:',
          divider: true,
          value: 'finished_at',
          class: 'primary white--text',
        },
        {
          text: 'Liberado Em:',
          divider: true,
          value: 'liberado_em',
          class: 'primary white--text',
        },
        {
          text: 'Saída do Pátio:',
          divider: true,
          value: 'saida_do_patio',
          class: 'primary white--text',
        },
        {
          text: 'Tef:',
          divider: true,
          value: 'tempoEmPatio',
          class: 'primary white--text',
        },
        {
          text: 'Controle saída por:',
          divider: true,
          value: 'colaborador_em_tansito',
          class: 'primary white--text',
        },
      ],
      expanded: [],
      options: {},
      loading: false,
    }
  },
  filters: {
    toLocaleDateTimeString,
  },

  computed: {
    ...mapState('patio', ['dashboardAprovados']),
    ...mapState('resumoTerminal', ['params']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    items() {
      return this.dashboardAprovados ? this.dashboardAprovados.results : []
    },
  },

  methods: {
    ...mapActions('patio', ['getAprovados', 'getDetalhesAprovados']),
    ...mapActions('template', ['errorMessage']),
    async getChamadas() {
      if (!Object.keys(this.params).length) return
      const { page, itemsPerPage } = this.options
      try {
        this.loading = true
        await this.getAprovados({
          proprietario: this.empresaAtual.public_id,
          page_size: itemsPerPage > 0 ? itemsPerPage : 1000,
          page: page,
          ...this.params,
        })
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.loading = false
      }
    },
    getTempoEmPatio(item) {
      if (item.triado_em !== null && item.saida_do_patio !== null) {
        const diferencaEmMs = Date.parse(item.saida_do_patio) - Date.parse(item.triado_em)
        return prettyMilliseconds(diferencaEmMs)
      } else {
        return '00:00:00'
      }
    },
    async getDetalhe({ item, value }) {
      // Não está sendo utilizado utilizado. item.chamadas já vem do serializer principal.
      if (item.chamadas || !value) {
        return
      }
      const data = await this.getDetalhesAprovados({
        created_by: item.created_by.public_id,
        finished_by: item.finished_by.public_id,
        terminal_origem: item.terminal_origem.public_id,
        terminal_destino: item.terminal_destino.public_id,
        created: item.created,
        finished: item.finished,
        // sub_produto: item.sub_produto.id,
      })
      item.chamadas = data
    },
    getStatus(statusID) {
      return Object.values(SolicitacaoBaixaStatus).find(
        (o) => o.id === statusID
      ).name
    },
  },

  async created() {},
}
</script>

<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
