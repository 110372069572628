<template>
  <v-row class="my-8">
    <v-col cols="12" md="12">
      <v-card v-if="canList">
        <v-row class="ma-0">
          <v-col cols="12" md="12">
            <div class="d-flex justify-space-between align-center mx-8">
              <v-card-title>Configurações</v-card-title>
              <div>
                <v-autocomplete
                  v-if="showSelectGate"
                  @change="handleGateSelecionado"
                  v-model="gateSelecionado"
                  label="Gate"
                  prepend-icon="mdi-domain"
                  :items="gates"
                  :item-text="(item) => `${item.business_name} - ${item.cnpj}`"
                  item-value="public_id"
                />
              </div>
              <div>
                <v-btn
                  color="primary"
                  class="ml-2"
                  :disabled="disableButtonFiltro"
                  @click="
                    () => {
                      this.showDashboard = 1
                      toggleFilters()
                    }
                  "
                  >Colunas</v-btn
                >
                <v-btn
                  color="secondary"
                  class="ml-2"
                  :disabled="disableButtonFiltro"
                  @click="search"
                  ><v-icon>mdi-magnify</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog v-model="showFilters" :max-width="500">
      <dashboard-filter @save="handleFilter" />
    </v-dialog>
  </v-row>
</template>

<script>
import DashboardFilter from './DashboardFilter.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import ContratoAPI from '@/services/tenants/contratos'
import { preferencias } from '@/mixins/preferencias'

export default {
  name: 'Dashboard',

  components: { DashboardFilter },
  mixins: [preferencias],

  data() {
    return {
      showDashboard: false,
      showFilters: false,
      showSelectGate: false,
      disableButtonFiltro: false,
      intervals: [],
      gates: [],
      gateSelecionado: {},
      tudoPopulado: false,
      intervaloDeAtualizacaoEmSegundos: 30,
    }
  },

  computed: {
    ...mapState('patio', ['dashboardFilters']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('resumoTerminal', ['params']),

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },

  methods: {
    ...mapMutations('patio', ['SET_DASHBOARD_FILTERS']),
    ...mapActions('patio', ['getServicos']),
    ...mapMutations('resumoTerminal', ['UPDATE_PARAMS']),
    search() {
      this.$emit('search')
    },

    async handleGateSelecionado() {
      if (this.preferencias) {
        this.preferencias.gate = this.gateSelecionado
      }
      this.UPDATE_PARAMS({
        ...this.params,
        gate: this.gateSelecionado,
        owner: this.empresaAtual.public_id,
        proprietario: this.empresaAtual.public_id,
      })
      this.disableButtonFiltro = false
      await this.getServicos({
        proprietario: this.empresaAtual.public_id,
        gate: this.gateSelecionado,
      })
    },

    async toggleFilters() {
      this.showFilters = !this.showFilters
    },

    handleFilter(filterItems) {
      const etapas = filterItems.map((etapa) => ({
        text: etapa.descricao,
        value:
          etapa.descricao.replaceAll(' ', '_').replaceAll('/', '_') + '.value',
      }))

      let campos = []

      campos.push({ text: 'Fila', value: 'descricao' })
      campos.push({ text: 'Última Chamada', value: 'ultima_chamada.value' })
      campos.push({
        text: 'Descarregado desde as 07:00',
        value: 'quantidade_descarregados_hoje.value',
      })
      campos.push({ text: 'TEF', value: 'tef.value' })

      etapas.forEach(function (value) {
        campos.push(value)
      })

      localStorage.setItem('headersResumoTerminal', JSON.stringify(campos))

      this.showFilters = false
    },

    removeIntervals() {
      if (this.intervals.length) {
        this.intervals.forEach((t) => clearInterval(t))
        this.intervals.splice(0)
      }
    },

    async updateDashboard() {
      const { dashboardItem } = this.$refs

      if (dashboardItem && dashboardItem.length) {
        await this.getTodosContadores(dashboardItem)
      } else {
        this.tudoPopulado = true
      }
    },

    async getTodosContadores(dashboardItem) {
      this.tudoPopulado = false

      await Promise.all(dashboardItem.map((el) => el.getContadores()))

      this.tudoPopulado = true
    },

    async getTodosLabels(dashboardItem) {
      this.tudoPopulado = false

      await Promise.all(dashboardItem.map((el) => el.getServicoLabel()))

      this.tudoPopulado = true
    },
  },

  async created() {
    if (this.empresaAtual.gate) {
      await this.getServicos({ proprietario: this.empresaAtual.public_id })
      this.showSelectGate = false
      this.disableButtonFiltro = false
      this.tudoPopulado = true
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates
      this.tudoPopulado = true
      this.showSelectGate = true
      this.disableButtonFiltro = true
      if (this.preferencias?.gate) {
        if (this.preferencias.gate?.public_id) {
          this.gateSelecionado = this.preferencias.gate.public_id
        } else {
          this.gateSelecionado = this.preferencias.gate
        }
        if (this.gateSelecionado) {
          this.handleGateSelecionado()
        }
      }
    }
  },

  beforeDestroy() {
    this.removeIntervals()
  },
}
</script>

<style>
.dashboard__item {
  height: 100%;
  width: 100%;
}
</style>
